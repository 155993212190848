import { observer } from "mobx-react";

import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import appStore from "@store/AppStore";
import { getPluralOrSingularString } from "@utilities";

const DeleteCatalogItemsDialog = () => {
  const selectedCatalogItemsAmount = appStore.ui.selectedCatalogItemIds.length;
  const selectedCatalogItemRefsAmount = appStore.ui.selectedCatalogItemReferenceIds.length;
  const amount = selectedCatalogItemsAmount + selectedCatalogItemRefsAmount;
  const showModal = appStore.ui.showDeleteCatalogItemsModal;

  if (!showModal) {
    return null;
  }

  const catalogItemIds = appStore.ui.selectedCatalogItemIds;
  // we want to ignore references that will be deleted along with the referenced item
  const refIdsToRemove: string[] = [];
  appStore.ui.selectedCatalogItemReferenceIds.forEach(referenceId => {
    const catalogItemReference = appStore.orgModel.catalogItems.getCatalogItemReference(referenceId);
    const id = catalogItemReference?.referencedCatalogItem?.id;
    if (!id || !appStore.ui.selectedCatalogItemIds.includes(id)) {
      refIdsToRemove.push(referenceId);
    }
  });

  const handleConfirm = () => {
    if (catalogItemIds.length) {
      appStore.orgModel.catalogItems.removeCatalogItems(catalogItemIds);
    }
    if (refIdsToRemove.length) {
      appStore.orgModel.catalogItems.bulkRemoveCatalogItemReference(refIdsToRemove);
    }
    appStore.ui.hideDeleteCatalogItemsModal();
    appStore.ui.setSelectedCatalogItemIds([]);
    appStore.ui.setSelectedCatalogItemReferenceIds([]);
  };

  return (
    <DeleteConfirmationDialog
      titleItem={`catalog ${getPluralOrSingularString(amount, "item")}`}
      description={`Are you sure you want to delete ${amount} catalog ${getPluralOrSingularString(amount, "item")}?`}
      onCancel={appStore.ui.hideDeleteCatalogItemsModal}
      onClose={appStore.ui.hideDeleteCatalogItemsModal}
      isOpen
      onConfirm={handleConfirm}
    />
  );
};

export default observer(DeleteCatalogItemsDialog);
