import * as GC from "@mescius/spread-sheets";

import { IAnalysis } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";

import { SPREADSHEET_CUSTOM_FUNCTION_NAME, SPREADSHEET_CUSTOM_WORKSHEET_NAME } from "./constants";

class GetInputCustomFunction extends GC.Spread.CalcEngine.Functions.Function {
  constructor() {
    // Call the base class constructor
    super(SPREADSHEET_CUSTOM_FUNCTION_NAME, 1, 1, {
      description: "Function for referencing rollup inputs",
      parameters: [{ name: "context" }, { name: "reference" }],
    });
  }
  acceptsReference(_index: any) {
    return true;
  }
  isContextSensitive() {
    return true;
  }

  // Evaluate method to handle the custom functionality
  evaluate(_context: any, reference: any) {
    // TODO: Keep below in case needed as ref. when working with outputs.
    // console.log(context);
    // console.log(reference);
    // const targetRow = reference.getRow();
    // const targetCol = reference.getColumn();
    // const formulaRow = context.source.getRow();
    // const formulaCol = context.source.getColumn();
    // const rowCount = reference.getRowCount();
    // const colCount = reference.getColumnCount();
    // const sheet = context.source.getSheet() as GC.Spread.Sheets.Worksheet;

    // console.log(sheet.getParent().getSheetCount());
    // Create and return a range object based on the reference
    // const range = new GC.Spread.Sheets.Range(row, col, rowCount, colCount);
    // Construct the formula reference based on the input
    // const formula = `=${SPREADSHEET_CUSTOM_WORKSHEET_NAME}!new_input`;
    // sheet.setFormula(targetRow, targetCol, formula);
    // make the below such that it uses the input map to find which input is referenced with the "new_input" label and return it's value.
    const referencedInput = appStore.workspaceModel?.analysis.analysisInputs.filter(input => input.label === (reference as string))[0];
    const value = referencedInput?.propertyReference?.numericValue;
    return value; // This internally replaces the formula with the value of the calculation of this custom function
  }
}

// If not already existing, add a new sheet called "Rollup_IO" to the end of the workbook
// No need to populate any inputs/outputs since if this is a new spreadsheet, there are no I/Os yet.
// and if this is not a new sheet, the I/Os will already be there in the "Rollup_IO" sheet.

export const initializeRollupIOSheet = (workbook: GC.Spread.Sheets.Workbook, analysis: IAnalysis) => {
  // Check if the sheet already exists
  let sheet = workbook.getSheetFromName(SPREADSHEET_CUSTOM_WORKSHEET_NAME);
  if (sheet) {
    console.debug(`Sheet "${SPREADSHEET_CUSTOM_WORKSHEET_NAME}" already exists`);
    // TODO: make sure the sheet is populated as it is supposed to be
    return;
  }
  sheet = new GC.Spread.Sheets.Worksheet(SPREADSHEET_CUSTOM_WORKSHEET_NAME);
  // TODO: decide if we need the below approach once we figure out how to handle outputs
  // Set cell A1-B1-C1 in the new sheet for inputs
  sheet.setValue(0, 0, "Input id");
  sheet.setValue(0, 1, "Input name");
  sheet.setValue(0, 2, "Input value");
  // Set cell D1-E1-F1 in the new sheet for outputs
  sheet.setValue(0, 3, "Output id");
  sheet.setValue(0, 4, "Output name");
  sheet.setValue(0, 5, "Output value");
  // Set column widths to 180
  sheet.setColumnWidth(0, 180);
  sheet.setColumnWidth(1, 150);
  sheet.setColumnWidth(2, 100);
  sheet.setColumnWidth(3, 180);
  sheet.setColumnWidth(4, 150);
  sheet.setColumnWidth(5, 100);

  // Add the new sheet
  workbook.addSheet(workbook.getSheetCount(), sheet);
  // Get inputs from the store and populate the sheet
  const inputs = analysis.inputs.map(input => ({ id: input.id, label: input.label, value: input.value }));
  const outputs = analysis.outputs.map(output => ({ id: output.id, label: output.label, value: output.value })); // TODO: does output.value really mean anything in our context where we will recalculate?
  for (let i = 0; i < inputs.length; i++) {
    const rowNumber = i + 1;
    sheet.setValue(rowNumber, 0, inputs[i].id);
    sheet.setValue(rowNumber, 1, inputs[i].label);
    sheet.setValue(rowNumber, 2, inputs[i].value);
    // TODO: Remove or add error checking to the below
    // sheet.addCustomName(`${inputs[i].label}`, `=${SPREADSHEET_CUSTOM_WORKSHEET_NAME}!$C$${rowNumber + 1}`, rowNumber, 2);
  }
  for (let i = 0; i < outputs.length; i++) {
    const rowNumber = i + 1;
    sheet.setValue(rowNumber, 3, outputs[i].id);
    sheet.setValue(rowNumber, 4, outputs[i].label);
    sheet.setValue(rowNumber, 5, outputs[i].value);
  }

  workbook.addCustomFunction(new GetInputCustomFunction());

  // Set a formula using the custom function
  workbook.getSheet(0).setValue(0, 0, "def");
  workbook.getSheet(0).setFormula(1, 1, '=INPUT("def")');
  workbook.getSheet(0).setFormula(2, 2, '=IF("def"="abc",TRUE(),FALSE())');
  workbook.getSheet(0).setValue(3, 3, "abc");
  workbook.getSheet(0).setFormula(4, 4, 'CONCAT("abc","def")');
};

// Check changes to inputs from the store and update the Rollup_IO sheet accordingly
// We cannot just clear the input columns and rewrite them because of two reasons:
// - If we clear the cells, the custom names still point to that cell
// - If we delete the custom names, we cannot re-use that name until all cells that use it are freed from it.
// - For deleted custom names, the worksheet will show NAME error
// So we need to search for each input in the input column and update the visible name and value, and
// if there is a new input, add that to the bottom of the list. If there is a deleted input, remove
// it from the sheet and delete those cells with move cells up option.
// Updates come one at a time: 1 change at a time, run the function. 1 new input, run the function.
// 1 deleted input, run the function. This also makes everything simpler.
// TODO: Check edge cases like if an input is removed from the store but there are cells that use it
// in the spreadsheet. What happens then?

// TODO: decide if we need the below approach once we figure out how to handle outputs
export const updateInputsOnTheWorksheet = (
  workbook: GC.Spread.Sheets.Workbook,
  newInputs: { id: string; label: string; value: string }[],
  oldInputs: { id: string; label: string; value: string }[]
) => {
  // Start from row 2, scan until an empty cell is found. If the cell is empty, it means there are no more inputs.
  const sheet = workbook.getSheetFromName(SPREADSHEET_CUSTOM_WORKSHEET_NAME);
  if (!sheet) {
    console.error(`Sheet ${SPREADSHEET_CUSTOM_WORKSHEET_NAME} not found`);
    return;
  }

  // Determine the action (addition, deletion, or modification)
  const addedInput = newInputs.find(newInput => !oldInputs.some(oldInput => oldInput.id === newInput.id));
  const deletedInput = oldInputs.find(oldInput => !newInputs.some(newInput => newInput.id === oldInput.id));
  const modifiedInput = newInputs.find(newInput => {
    const oldInput = oldInputs.find(oldInput => oldInput.id === newInput.id);
    return oldInput && (oldInput.label !== newInput.label || oldInput.value !== newInput.value);
  });

  if (addedInput) {
    // Handle added input
    let lastRowNumber = 1;
    while (lastRowNumber < sheet.getRowCount() && sheet.getValue(lastRowNumber, 0)) {
      lastRowNumber++;
    }
    sheet.setValue(lastRowNumber, 0, addedInput.id);
    sheet.setValue(lastRowNumber, 1, addedInput.label);
    sheet.setValue(lastRowNumber, 2, addedInput.value);
  } else if (deletedInput) {
    // Handle deleted input
    for (let row = 1; row < sheet.getRowCount(); row++) {
      const cellValue = sheet.getValue(row, 0);
      if (cellValue === deletedInput.id) {
        // sheet.moveTo() // TODO! fix such that this deletes 3 cells moving up
        break;
      }
    }
  } else if (modifiedInput) {
    // Handle modified input
    for (let row = 1; row < sheet.getRowCount(); row++) {
      const cellValue = sheet.getValue(row, 0);
      if (cellValue === modifiedInput.id) {
        sheet.setValue(row, 1, modifiedInput.label);
        sheet.setValue(row, 2, modifiedInput.value);
        break;
      }
    }
  }
  addedInput && console.debug("Added input:", addedInput);
  deletedInput && console.debug("Deleted input:", deletedInput);
  modifiedInput && console.debug("Modified input:", modifiedInput);
};

export const renameInputsInCustomFunctions = (
  workbook: GC.Spread.Sheets.Workbook,
  newInputs: { id: string; label: string; value: string }[],
  oldInputs: { id: string; label: string; value: string }[]
) => {
  // Determine the inputs which are renamed
  let modifiedOldInputLabel = "";
  let modifiedNewInputLabel = "";
  const modifiedInput = newInputs.find(newInput => {
    const oldInput = oldInputs.find(oldInput => oldInput.id === newInput.id);
    if (oldInput && oldInput.label !== newInput.label) {
      modifiedOldInputLabel = oldInput.label;
      modifiedNewInputLabel = newInput.label;
      return true;
    }
  });

  if (!modifiedInput) {
    return;
  }

  console.debug("Modified old input label:", modifiedOldInputLabel);
  console.debug("Modified new input label:", modifiedNewInputLabel);

  searchAndReplaceAllCustomFunctionInstances(workbook, modifiedOldInputLabel, modifiedNewInputLabel);
};

export const searchAndReplaceAllCustomFunctionInstances = (
  workbook: GC.Spread.Sheets.Workbook,
  oldInputLabel: string,
  newInputLabel: string
) => {
  const oldFormulaPart = `${SPREADSHEET_CUSTOM_FUNCTION_NAME}("${oldInputLabel}")`;
  const newFormulaPart = `${SPREADSHEET_CUSTOM_FUNCTION_NAME}("${newInputLabel}")`;

  const searchCondition = new GC.Spread.Sheets.Search.SearchCondition();
  searchCondition.searchString = oldFormulaPart;
  searchCondition.searchTarget = GC.Spread.Sheets.Search.SearchFoundFlags.cellFormula;
  searchCondition.startSheetIndex = 0;
  searchCondition.endSheetIndex = workbook.getSheetCount() - 1; // TODO: this includes the Rollup_IO sheet, remove later if needed

  let result = workbook.search(searchCondition);

  while (result.foundString) {
    if (workbook.getSheet(result.foundSheetIndex).getFormula(result.foundRowIndex, result.foundColumnIndex)) {
      console.debug(
        "Formula match:",
        workbook.getSheet(result.foundSheetIndex).name(),
        result.foundRowIndex,
        result.foundColumnIndex,
        result.foundString
      );
      const newFormula = (result.foundString as string).replace(oldFormulaPart, newFormulaPart);
      workbook.getSheet(result.foundSheetIndex).setFormula(result.foundRowIndex, result.foundColumnIndex, newFormula);
    } else {
      console.debug(
        "Non-formula match:",
        workbook.getSheet(result.foundSheetIndex).name(),
        result.foundRowIndex,
        result.foundColumnIndex,
        result.foundString
      );
    }
    result = workbook.search(searchCondition);
  }
};
